import React from "react"

function Header({dataTitle}) {

    return (
        <div id="titleDiv">
            <h1>CSV Modifier</h1>
            <h3>For DM &#40;and others&#41; at Rightpoint</h3>
        </div>
    )
}

export default Header 